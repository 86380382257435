export default {
    // 临时订单

    ptzhanghao: {
        bqgl: 'Label management',
        bqgl1: 'Generate order',
        bqgl2: 'platform',
        bqgl3: 'nation',
        bqgl4: 'shop',
        bqgl5: 'Order number',
        bqgl6: 'Delivery address',
        bqgl7: 'Order time',
        bqgl8: 'Pull time',
        bqgl9: 'SKU Pictures',
        bqgl10: 'Product name',
        bqgl11: 'Turn-in time',
        ls1: 'status',
        ls2: 'Temporary order',
        ls3: 'Cancelled',
        ls4: 'Cancel',
        a0: 'All order',
        a1: 'Temporary order',
        a2: 'Transferred order',
        a3: 'shopify',

        b3: 'Controls',





    },
    // 首页翻译
    home: {
        gglb1: 'List of announcements',
        gglb2: 'title',
        gglb3: 'Release time',
        gglb4: 'SKU warning',
        gglb5: 'Inventory management',
        gglb6: 'Order statistics',
        gglb7: 'Start date',
        gglb8: 'End date',
        gglb9: 'today',
        gglb10: 'yesterday',
        gglb11: 'Last 7 days',
        gglb12: 'Last 30 days',
        gglb13: 'all',
        gglb14: 'Financial information',
        gglb15: 'Available amount = balance',
        gglb16: 'Credit extension',
        gglb17: 'Temporary capacity',
        gglb18: 'Frozen',
        gglb19: 'Work order statistics',
        gglb20: 'Latest News',
        gglb21: 'More...',
        gglb22: 'Service ticket',
        gglb23: 'Wait for reply',
    },
    // 菜单翻译
    setyj: {
        kongzhitai: 'Console',
        guoneifahuo: 'Domestic Large Cargo',
        zxxb: 'Dedicated parcel',
        kuaididingdan: 'Express order',
        haiwaifahuo: 'Overseas shipments',
        caiwuzhongxin: 'Finance Center',
        syssettings: 'System settings',
        ptdr: 'Platform Management',

    },
    //二级翻译
    seterj: {
        gnlist: 'Order List',
        gnwaybill: 'Forecast order',
        gnpilsc: 'Batches',
        ddlist: 'Shipments',
        zlist: 'Order List',
        zwaybill: 'Forecast order',
        zpilsc: 'Batches',
        rcdgl: 'Warehouse order',
        waybill: 'New Shipment',
        pilsc: 'Batches',
        hwddlist: 'Order List',
        hwplsc: 'Batches',
        hwybdd: 'Forecast order',
        xjrk: 'Inventory management ',
        rkjl: 'Inventory records',
        ddgl: 'Address management',
        caiwuls: 'Ainancial flows',
        dhzd: 'Bulk billing',
        qdjg: 'Channel price',
        gdlb: 'Ticket list',
        xtpz: 'System Configuration',
        ptgl: 'Platform Management',
        xbzd: 'Small packet billing',
        dscz: 'Warehouse Rental Reminder',
        skugl: 'SKU management',
        xstj: 'Sales Statistics',
        ddxg: 'Order related',
        ptzh: 'Platform account',
        skugx: 'SKU relationship',
        zcmd: 'Autobiographical sheet',
        hwsf: 'SCANFORM',
        kdsf: 'SCANFORM',
        lsddlb: 'Temporary Order',
        kcdc: 'Inventory adjustment',

    },
    // 快递打单-预报订单
    kdyddd: {
        sj_name: ' Name',
        sj_tel: ' Phone',
        sj_gs: ' Company',
        sj_dizhi: ' Addres',
        sj_ycdizhi: ' Addres 2',
        sj_chengshi: ' City',
        sj_zouseng: ' State',
        sj_youbian: 'PostCode',
        sj_gj: ' Country',
        dd_bh: 'OrderID',
        sj_zdybz: 'Reference',
        insurance: 'Insured',
        sjxinxi: 'Receiving Information',
        orderSIZE: 'Order Settings',
        xzsl: 'Number of boxes',
        unitsize: 'Unit setting',
        xts: 'Tip:',
        xtswenzi: 'You can edit it in Excel, copy and paste it into the table below.',


    },
    // 快递打单-预报订单placeholder
    placeholder: {
        sj_name: 'Please enter the recipient name',
        sj_tel: 'Please enter the recipient phone number',
        sj_gs: 'Please enter the recipient company',
        sj_dizhi: 'Please enter the shipping address',
        sj_ycdizhi: 'Used when the address is too long',
        sj_chengshi: 'Please enter the recipient city',
        sj_zouseng: 'Please enter the recipient state or province',
        sj_youbian: 'Please enter the recipient postal code',
        sj_gj: 'Please select a country',
        dd_bh: 'Automatically generated when empty',
        sj_zdybz: 'Commonly used for notes on labels',
        insurance: 'Not insured for empty space (requires channel support)',
        dizhiku: 'Save to recipient address library',
        fbayundan: 'FBA waybill',
        xtzlcc: 'Same weight and size',
        xhws: 'Box number digits',




    },
    // 快递打单-批量上传
    kdpilsc: {
        pilisngTips: 'This function is only applicable to single item orders, and orders with multiple items cannot be imported in bulk temporarily.',
        mobanTips: 'template',
        xzplmbTips: 'Download bulk templates',
        ddpcTips: 'Order Batch:',
        zlszTips: 'Weight setting:',
        scwjTips: 'Upload file:',
        ddnumTips: 'Order quantity:',
        sclistTips: 'Upload List',
        xitlistTips: '(Note: The system only retains 30 days of Excel import records!)',
        piliangTips: 'Batch Delete',
        pichiTips: 'batch',
        fileTips: 'file',
        ordernumTips: 'Order Quantity',
        successnumTips: 'Successful quantity',
        failnumTips: 'Number of failures',
        uptime1Tips: 'uploaded',
        caozuoTips: 'operate',
    },
    // 快递打单-订单列表
    kdlist: {
        gjz: 'keyword',
        gjzpay: 'Please enter keyword (order number/recipient/tracking number/Batch upload batch)',
        pi: 'batch',
        ysfs: 'Mode of transport',
        ysfspay: 'Please select a shipping method',
        zlfw: 'Weight range',
        zlfwpay: 'Please enter weight range (kg)',
        fjdz: 'Sending address',
        fjdzpay: 'Please enter the sending address ID',
        xuan: 'choose',
        cxfs: 'Query mode',
        cxfspay: 'Select the batch query mode',
        dorpay: 'Order number or tracking number batch query, one per line (this query only supports precise query, not fuzzy query)',
        kdcx: 'Express inquiry',
        all: 'All',
        ydy: 'printed',
        wdy: 'unprinted',
        sbdd: 'Failed order',
        wtk: 'No refund',
        ddk: 'To be refunded',
        ytk: 'Refunded',
        bntk: 'non-refundable',
        djz: 'frozen',
        rifw: 'Date range',
        ksrq: 'Start date',
        jsrq: 'End date',
        jr: 'today',
        zr: 'yesterday',
        jqr: 'Last 7 days',
        jssr: 'Last 30 days',
        hcdspt: 'Send back to the e-commerce platform',
        ysxc: '',
        tddxx: 'order information has been selected',
        sx: 'screen',
        cz: 'reset',
        hc0: 'To be returned',
        hc1: 'Returning',
        hc2: 'Successful return',
        hc3: 'Return failure',
        cx1: 'Not yet queried',
        cx2: 'Waiting for collection',
        cx3: 'In transit',
        cx4: 'Arrive for collection',
        cx5: 'Delivery failure',
        cx6: 'Possible exception',
        cx7: 'Sign successfully',
        ssjg: 'Trial price',
        sckdd: 'Generate express order',
        dyxtbq: 'Print system label',
        dyxtbq1: 'ZPl printing sheet',

        tswlpt: 'Push logistics platform',
        zzt: 'substate',
        mdxz: 'Sheet download',
        mdxz1: 'Merge download',
        mdxz2: ' Package download (by order number)',
        mdxz3: 'Package download (by tracking number)',
        mdxz4: 'Pack and download (by waybill number)',
        mdxz5: ' Pack and download (Order number @ Tracking number)',

        cjdd: 'Create an order',




    }
}